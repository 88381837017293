@charset "utf-8";


  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYBLACKITALIC.woff') format('woff');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYBOLD.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYHEAVYITALIC.woff') format('woff');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYLIGHTITALIC.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYMEDIUM.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYREGULAR.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYSEMIBOLDITALIC.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYTHINITALIC.woff') format('woff');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: url('/assets/styles/fonts/sf-pro-display/SFPRODISPLAYULTRALIGHTITALIC.woff') format('woff');
    font-weight: 200;
    font-style: italic;
  }

    body {
      font-family: 'SF Pro Display', sans-serif;
      font-weight: 400; /* Für Regular */
      font-style: normal;
  }
  h1 {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 700; /* Für Bold */
    font-style: normal;
    }

  .slick-track
  {
      display: flex !important;
  }
  
  .slick-slide
  {
      height: inherit !important;
  }
  .slick-list {margin: 0 -5px;}
  .slick-slide>div {padding: 0 5px;}

  .container-box {
    position: relative;
    /*width: 300px; /* Breite des Divs anpassen */
    height: 200px; /* Höhe des Divs anpassen */
    background-color: lightgray; /* Hintergrundfarbe des Divs */
    overflow: hidden; /* Verhindern, dass der Text aus dem Div herausragt */
  }

  .overlay-box {
    position: absolute;
    top: 0;
    left: -100%; /* Initialposition links außerhalb des Divs */
    width: 100%;
    height: 100%;
    background-color: rgba(71, 71, 71, 0.637); /* Hintergrundfarbe des Overlays */
    color: white;
    display: flex;
    padding: 5px;
    font-size: 14px;
    justify-content: center; 
    align-items: center; 
    transition: left 0.3s ease; 
  }

  .container-box:hover .overlay-box {
    left: 0; 
  }

  /* Verstecke alle Bedienelemente außer dem Stummschaltungsbutton */
video::-webkit-media-controls-panel {
  display: none!important;
}

/* Zeige nur den Stummschaltungsbutton */
video::-webkit-media-controls-volume-slider {
  display: block!important;
}

.zoom-element:hover {
  transform: scale(1.05); /* Vergrößert das Element um 20% */
  transition: transform 0.3s ease; /* Fügt eine sanfte Übergangseffekt hinzu */
}
.zoom-element {
  transition: transform 0.5s ease;
}

.pointer{
  cursor: pointer;
}


.cookie-banner {
  position: fixed;
  bottom: 10px; /* Unten links */
  left: 10px; /* Unten links */
  width: auto;
  background-color: #f3d6d6; /* Hellroter Hintergrund */
  border-radius: 10px; /* Border Radius: 10px */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Schwarzer Schatten */
  padding: 20px;
  text-align: left;
  z-index: 100;
}

.cookie-banner p {
  margin: 0;
}
#accept-cookies,
#reject-cookies {
  margin-top: 10px;
  font-size: 14px!important;
}
#reject-cookies {
  margin-left: 10px;
  font-size: 14px!important;
}

.footer-mrl{
  margin-left: 3rem;
  margin-right: 3rem;
}

@media (max-width: 908px) {
  .footer-mrl{
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .deviceSelection .option {
    padding: 15px 50px!important;
  }
}
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.slider-container {
  height: 100%;
}

// Update Bulma's global variables
$family-sans-serif:      "SF Pro Display", sans-serif!important;
$grey-dark:              #757763;
$grey-light:             #d0d1cd;
$primary:                #ce5151;
$link:                   #e75c5c;

// Update some of Bulma's component variables
$input-background-color: #eff0eb;
$control-border-width:   2px;
$input-border-color:     transparent;
$input-shadow:           none;
 
@import "~bulma/bulma";
// Import Component main Sass File bulma-o-steps
@import '~@creativebulma/bulma-collapsible';
@import "~bulma-timeline";
@import "bulma-o-steps/bulma-steps.min.css";
@import 'bulma-carousel/dist/css/bulma-carousel.min.css';
@import 'aos/dist/aos.css';