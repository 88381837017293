.banner-gradient{
  background-image:
          linear-gradient(135deg, #ce1b28b0 0%, #111111a3 74%),
          url("../images/_24/mood/title.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.kogo-red {
  background: #ce5151;
  box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.025);
  color:white!important;
}

.white-text { color:white; }
.foot-pad {padding-left: 1.5rem}

.hero-carousel {
  position: relative;
  width: 100%;
  height: 100vh; /* Volle Höhe des Viewports */
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 1s ease-in-out;
  width: calc(100% * 2); /* Zwei Slides nebeneinander */
  height: 100%; /* Höhe auf 100% */
}

.carousel-item {
  flex: 0 0 100%; /* Jeder Slide nimmt 100% der Breite ein */
  height: 100%;
  display: flex;
  align-items: center;
  position: relative; /* Für den Text-Overlay */
}

/* Bild */
.carousel-item img.is-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 30%; /* Das Bild wird oben zentriert */
  position: relative; /* Bild bleibt im normalen Fluss */
}

/* Textblock */
.carousel-content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start; /* Links ausgerichtet */
}

.text-content {
  width: 20%; /* Textblock nimmt jetzt 20% des Slides ein */
  height: 100%; /* Volle Höhe */
  background: linear-gradient(to right, rgba(206, 81, 81, 0.54), rgba(206, 81, 81, 0)); /* Farbverlauf von links nach rechts */
  color: white;
  padding-left: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left; /* Links ausgerichteter Text */
  position: absolute; /* Text über dem Bild */
  top: 0; /* Positionierung über dem Bild */
  left: 0; /* Textblock beginnt links */
  z-index: 1; /* Text über dem Bild */
}
.tag-koho{
  width:20%
}
@media (max-width: 768px) {
  .tag-koho{
    width:45%!important;
  }
  .head-1-mvs{
    font-size: 2.5rem!important;
  }
  .carousel-item {
      flex: 0 0 100%; /* Sicherstellen, dass der Slide 100% der Breite einnimmt */
  }

  .text-content {
      width: 100%; /* Textblock auf volle Breite */
      padding: 15px;
      position: absolute;
      bottom: 0;
      background: rgba(206, 81, 81, 0.7); /* Feste Farbe auf mobilen Geräten */
      font-size: 0.8rem; /* Text kleiner */
      box-sizing: border-box; /* Padding wird zur Breite gezählt */
  }

  /* Pfeile ausblenden */
  .carousel-control {
      display: none; /* Pfeile nicht anzeigen */
  }
  .carousel-track {
    width: 100%; /* Setze die Breite auf 100% */
}
}

/* Steuerungen */
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 100;
}

.carousel-control.left {
  left: 10px;
}

.carousel-control.right {
  right: 10px;
}

.sticky-box {
  position: sticky;
  top: 70px; /* The distance from the top of the viewport */
  z-index: 10; /* Ensure it stays above overlapping content */
  background-color: white; /* Optional: Prevent content bleed through */
}

/* Box Wrapper */
.repair-box-wrapper {
  //max-width: 600px;
  //margin: 10px auto;
}

/* Repair Box */
.repair-box {
  flex: 1;
  border: 1px solid #d3d3d352;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  display: block; /* Macht das Label klickbar */
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

/* Hover Effekt */
.repair-box:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Checkbox-Auswahl: Nur die Box erhält die grüne Umrandung */
input[type="checkbox"]:checked + .repair-box {
  border-color: #329932; /* Grüner Rahmen */
  background-color: #f4fef4; /* Hellgrüner Hintergrund */
}

/* Repair Header */
.repair-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.repair-title h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.repair-title {
  text-align: left;
  padding-left: 20px;
}

.repair-title .duration {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 500;
}

/* Preis-Tag */
.price-tag {
  background-color: #eaf5df;
  color: #5AAA2A;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 10px;
  white-space: nowrap;
}

/* Unsichtbare Checkbox */
.repairInput {
  display: none;
}
.searchVisib{
  display:flex!important;
}
@media (max-width: 768px) {
  .repair-box-wrapper {
      width: 100%; /* Auf kleineren Screens untereinander */
  }
  .searchVisib{
    display:flex!important;
  }
}



.button.is-next-bt {
  background-color: hsl(97.5deg 60.38% 41.57%);
  color: white;
    border: none;
}

#selectRepair {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.repair-box-wrapper {
  width: calc(50% - 5px); /* Zwei Boxen nebeneinander mit Abstand */
  display: flex;
}

@media (max-width: 768px) {
  .repair-box-wrapper {
      width: 100%; /* Auf kleineren Screens untereinander */
  }
}

.repair-left {
  display: flex;
  align-items: center;
  gap: 10px; /* Abstand zwischen Bild und Titel */
}

.repair-icon {
  width: 30px; /* Passe die Bildgröße an */
  height: 30px;
}




.modal-card-head, .modal-card-foot {
  background-color: hsl(0deg 0% 100%)!important;
}

.arrow-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EAF5DF; /* Hellgrün */
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.arrow-button svg {
  width: 24px;
  height: 24px;
  fill: #5AAA2A; /* Dunkelgrün */
  transition: fill 0.3s ease-in-out;
}

.arrow-button:hover {
  background-color: #5AAA2A; /* Dunkelgrün */
}

.arrow-button:hover svg {
  fill: white;
}

.search-container {
  position: relative;
  max-width: 300px;
}

.search-input {
  border: 1px solid #EAF5DF; /* Grüne Umrandung */
  border-radius: 12px; /* Abgerundete Ecken */
  padding-left: 40px; /* Platz für das Icon */
  height: 45px; /* Höhe des Inputs */
  width: 100%;
  background-color: white;
}

.search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  fill: #8DC776; /* Grüne Icon-Farbe */
  z-index: 100;
}



.steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow) {
  background-color: #8DC776;
  color: #fff;
}
.steps-segment:after {
  background-color: #8DC776;
}
.steps:not(.is-hollow) .steps-marker:not(.is-hollow) {
  background-color: #8DC776;
  color: #fff;
}

.is-flexiMobi{
  display:flex!important;
}
@media (max-width: 768px) {
  .is-flexiMobi{
    display:inline-block!important;
  }
  .search-container {
    margin-top: 20px;
  }
}


@media (max-width: 768px) {
  .steps-segment {
      display: none!important;
  }
}

        .shipping-option {
            display: flex;
            flex-direction: column;
            border: 2px solid #ddd;
            border-radius: 12px;
            padding: 15px;
            margin-bottom: 10px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transition: all 0.3s ease;
        }
        .shipping-option input {
            display: none;
        }
        .shipping-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .shipping-label img {
            width: 30px;
            margin-right: 10px;
        }
        .shipping-text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
        .shipping-title {
            font-weight: bold;
        }
        .shipping-description {
            font-size: 0.8em;
            color: #666;
        }
        .shipping-price {
            background: #4CAF50;
            color: white;
            padding: 5px 15px;
            border-radius: 8px;
            font-weight: bold;
            white-space: nowrap;
        }
        .shipping-option:has(input:checked) {
            border: 2px solid #4CAF50;
        }
        .shipping-conditions {
            display: none;
            font-size: 0.9em;
            color: #333;
            margin-top: 10px;
            padding: 10px;
            background: #f9f9f9;
            border-radius: 8px;
            width: 100%;
        }
        #dhl-shipping:checked ~ .shipping-conditions {
            display: block;
        }
        .shipping-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .shipping-header img {
            width: 30px;
            margin-right: 10px;
        }
        .shipping-header .shipping-text {
            flex-grow: 1;
        }

        .shipping-option {
          display: flex;
          flex-direction: column;
          border: 2px solid #ddd;
          border-radius: 12px;
          padding: 15px;
          margin-bottom: 10px;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: all 0.3s ease;
      }
      
      .shipping-option input {
          display: none; /* Unsichtbar, aber aktiv */
      }
      
      .shipping-option input:checked + label .shipping-header {
      }
      
      .shipping-option input:checked + label .shipping-conditions {
          display: block; /* Bedingungen anzeigen, wenn ausgewählt */
      }
      
      .shipping-conditions {
          display: none;
          font-size: 0.9em;
          color: #333;
          margin-top: 10px;
          padding: 10px;
          background: #f9f9f9;
          border-radius: 8px;
          width: 100%;
      }
      

input[type="radio"] + label {
  padding-left: 0.875rem!important;
}
input[type="checkboxs"] + label:before, input[type="radio"] + label:before {
  content:none!important;
}


/* Enhanced Bulma Steps with Numbers and Animation Effects */

/* Using CSS variables for easier customization */
:root {
  --steps-active-color: #5aaa2a;  /* More vibrant green */
  --steps-completed-color: #5aaa2a;
  --steps-default-color: #dbdbdb;
  --steps-text-color: #ffffff;
}

/* Base styling for all step markers - make them flex containers for centering numbers */
.steps .steps-marker {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
  transition: all 0.4s ease !important;
}

/* Adding numbers to the markers */
.steps .steps-segment:nth-child(1) .steps-marker::before { content: "1"; }
.steps .steps-segment:nth-child(2) .steps-marker::before { content: "2"; }
.steps .steps-segment:nth-child(3) .steps-marker::before { content: "3"; }
.steps .steps-segment:nth-child(4) .steps-marker::before { content: "4"; }

/* Active step styling with more vibrant green */
.steps .steps-segment.is-active .steps-marker {
  background-color: var(--steps-active-color) !important;
  box-shadow: 0 0 0 5px #5aaa2a45 !important;
  transform: scale(1.15) !important;
}

/* Pulsing glow animation for active step */
@keyframes glow-pulse {
  0% { box-shadow: 0 0 0 0 #5aaa2a45; }
  70% { box-shadow: 0 0 0 15px rgba(0, 230, 119, 0.123)}
  100% { box-shadow: 0 0 0 0 #5aaa2a; }
}

.steps .steps-segment.is-active .steps-marker {
  animation: glow-pulse 2s infinite;
}

/* Bouncy hover effect for all markers */
.steps .steps-segment .steps-marker:hover {
  transform: scale(1.2) !important;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  cursor: pointer !important;
}

/* Completed steps styling */
.steps .steps-segment.is-completed .steps-marker {
  background-color: var(--steps-completed-color) !important;
}

/* Enhanced connector styling - thicker and animated */
.steps .steps-segment:not(:last-child)::after {
  height: 0.35em !important;
  transition: background-color 0.6s ease !important;
}

.steps .steps-segment.is-active:not(:last-child)::after,
.steps .steps-segment.is-completed:not(:last-child)::after {
  background-color: var(--steps-active-color) !important;
}

/* Entrance animation for the entire steps component */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.steps {
  animation: fadeInUp 0.8s ease-out;
}

/* Making the active segment's text bold and larger */
.steps .steps-segment.is-active .steps-content p {
  font-weight: bold !important;
  color: var(--steps-active-color) !important;
}

/* Der Connector NACH dem aktiven Element bleibt in der Standardfarbe */
.steps .steps-segment.is-active:not(:last-child)::after {
  background-color: var(--steps-default-color) !important;
}